import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, of, switchMap } from 'rxjs';
import { LoggedUserPermissionsMisc } from '../models/loggedUserPermissionsMisc.model';

export const canSeeApplicationGuard: CanActivateFn = () => {
  const router = inject(Router);
  return inject(Store).pipe(
    select((state) => {
      return state.Auth.permissions as LoggedUserPermissionsMisc;
    }),
    filter((loaded) => !!loaded),
    switchMap((loggedUserPermissionsMisc) => {
      if (loggedUserPermissionsMisc?.canReadApplications) {
        return of(true);
      } else {
        router.navigate(['/']);
        return of(false);
      }
    }),
  );
};
