<!-- Users Header -->
<button
  class="flex h-16 w-full max-w-full items-center gap-4 pl-8 pr-4"
  (click)="toggle()"
>
  <div class="flex w-[calc(16.6666%-16px)] shrink-0 items-center gap-5">
    <img
      src="/assets/icons/Reishauer_Icon_Users.svg"
      width="30px"
      alt="Users"
    />
    <h2 class="font-brand-regular text-xl text-[#7a93cb]">
      {{ "unitUsers.title" | translate }}
    </h2>
  </div>

  @if (isToggled && canAddUsers) {
    <button
      (click)="$event.preventDefault(); $event.stopPropagation(); addMember()"
      class="flex w-fit shrink-0 items-center gap-2 rounded-md border bg-white p-2 hover:bg-brand-blue-light"
    >
      <p class="text-sm uppercase text-[#1F3D7D]">
        {{ "unitUsers.addUserButton" | translate }}
      </p>
      <img
        class="h-6 w-6"
        src="/assets/icons/Reishauer_Icons-Add.svg"
        alt="Icon add"
      />
    </button>
  } @else {
    @if (unitUsers) {
      <div class="flex w-fit shrink-0 items-center">
        <img src="/assets/icons/person.svg" width="30px" alt="person logo" />
        <p class="text-base text-[#7a93cb]">
          {{ unitUsers.userCount.total + "/" + unitUsers.userCount.max }}
        </p>
      </div>
    }
    @let ciamAdmins = getCiamAdmins();
    @if (ciamAdmins) {
      <div
        class="flex w-full max-w-[calc(100%-(16.666%+146px))] shrink-0 items-center gap-2 text-left"
        [title]="ciamAdmins"
      >
        <h3 class="text-base text-brand-blue-900">
          {{ "unitUsers.admins" | translate }}:
        </h3>
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ ciamAdmins }}
        </p>
      </div>
    }
  }

  <div class="flex w-full min-w-6 justify-end">
    <img
      src="/assets/icons/chevron-down.svg"
      width="22px"
      alt="chevron"
      [ngClass]="isToggled ? 'rotate-180' : 'rotate-0'"
    />
  </div>
</button>

<!-- Users Content  -->
@if (isToggled && unitUsers) {
  <div class="w-full py-4 pl-[82px] pr-8">
    @if (unitUsers.userCount.total > 0) {
      <ol
        class="grid w-full gap-x-4 divide-y overflow-x-auto text-[#3c5792]"
        style="grid-template-columns: repeat(6, auto)"
      >
        <li
          class="col-span-full grid grid-cols-subgrid text-sm uppercase text-[#7a93cb]"
        >
          <button
            class="flex items-center gap-2"
            (click)="sortName()"
            [title]="'unitUsers.name' | translate"
          >
            <p class="overflow-hidden text-ellipsis whitespace-nowrap">
              {{ "unitUsers.name" | translate }}
            </p>
            <img
              src="/assets/icons/chevron-down.svg"
              width="20px"
              alt=""
              [ngClass]="sortNameOrder === 'asc' ? 'rotate-0' : 'rotate-180'"
            />
          </button>
          <button
            class="flex items-center gap-2"
            (click)="sortDepartment()"
            [title]="'unitUsers.department' | translate"
          >
            <p class="overflow-hidden text-ellipsis whitespace-nowrap">
              {{ "unitUsers.department" | translate }}
            </p>
            <img
              src="/assets/icons/chevron-down.svg"
              width="20px"
              alt=""
              [ngClass]="
                sortDepartmentOrder === 'asc' ? 'rotate-0' : 'rotate-180'
              "
            />
          </button>
          <p>{{ "unitUsers.ciamRole" | translate }}</p>
          <p>Academy</p>
          <p>Monitoring</p>
          <p></p>
        </li>
        @for (user of unitUsers.users; track user.id) {
          <li class="col-span-full grid grid-cols-subgrid items-center py-1">
            <p
              class="w-full min-w-16 max-w-40 overflow-hidden text-ellipsis whitespace-nowrap"
              [title]="user.email"
            >
              {{ user.givenName }} {{ user.familyName }}
            </p>

            <p
              class="w-full min-w-5 max-w-28 overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {{ user.department.name }}
            </p>

            <rh-admincenter-multi-select
              class="w-full min-w-28 max-w-44"
              [allowMultiSelect]="false"
              [roles]="getRolesByApplicationId(getCiamId())"
              [selectedRoles]="
                getSelectedRolesByApplicationId(user, getCiamId())
              "
              (selectionChange)="
                onRoleChangeMulti(user.id, getCiamId(), $event)
              "
            />

            <ng-container
              [ngTemplateOutlet]="tplApplication"
              [ngTemplateOutletContext]="{
                a: getClustersForOverview()[0],
                b: user,
              }"
            />

            <ng-container
              [ngTemplateOutlet]="tplApplication"
              [ngTemplateOutletContext]="{
                a: getClustersForOverview()[1],
                b: user,
              }"
            />

            <div
              class="flex w-full shrink-0 items-center justify-end gap-2"
              [ngClass]="user.isBlocked ? 'min-w-24' : 'min-w-12'"
            >
              @if (user.isBlocked) {
                <img
                  src="/assets/icons/Reishauer_Icon_Block_User.svg"
                  alt="Icon block user"
                  class="h-6 w-auto shrink-0"
                />
              }
              <button
                (click)="editMember(user)"
                class="flex h-9 shrink-0 items-center rounded-md border bg-white px-3 py-2 hover:bg-brand-blue-light"
              >
                <img
                  class="h-6 w-6"
                  src="/assets/icons/Reishauer_Icons-Edit.svg"
                  alt="Icon edit"
                />
              </button>
            </div>
          </li>
        }
      </ol>
    }
    @if (unitUsers.userCount.total === 0) {
      <div>
        <p class="text-brand-blue-200">
          {{ "unitUsers.noUsers" | translate }}
        </p>
      </div>
    }
  </div>
}

<ng-template #tplApplication let-cluster="a" let-user="b">
  <div class="flex h-full w-full gap-2">
    @for (application of cluster.applications; track application) {
      <div class="flex h-full items-center gap-1 font-medium">
        @for (role of getRolesByApplicationId(application.id); track role) {
          <button
            (click)="
              onRoleChange(
                user,
                application.id,
                role.id,
                application.multiRolesAllowed
              )
            "
            class="flex h-12 w-full max-w-24 items-center justify-between gap-2 bg-[#f4f4f4] p-2 font-medium text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f] 2xl:max-w-full"
          >
            @if (role.key.includes("_viewer")) {
              <p
                class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
                [title]="application.name"
              >
                {{ application.name }}
              </p>
            }
            @if (role.key.includes("elearning_admin")) {
              <p class="w-full" title="Admin">A</p>
            }
            @if (role.key.includes("elearning_editor")) {
              <p class="w-full" title="Editor">E</p>
            }
            @if (role.key.includes("elearning_approver")) {
              <p class="w-full" title="Approver">P</p>
            }
            <div
              class="flex aspect-square w-[34px] shrink-0 items-center bg-white p-2 has-[img]:bg-[#86a337]"
            >
              @if (userHasRoleForApplication(user, application.id, role.id)) {
                <img
                  src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                  alt="Checkmark"
                  class="aspect-auto w-full"
                />
              }
            </div>
          </button>
        }
      </div>
    }
  </div>
</ng-template>
