import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rh-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class DropdownComponent {
  public listVisible = false;

  @Input()
  public model?: string;

  @Input()
  public title?: string;

  private _items?: string[];
  @Input()
  public get items(): string[] | undefined {
    return this._items;
  }
  public set items(v: string[] | undefined) {
    this._items = v;
  }

  @Input()
  public placeholder = '';

  @Input()
  public clearable = false;

  @Input()
  public disabled = false;

  @Input()
  public invalidIfEmpty = true;

  @Input()
  public blueMode = false;

  @Output()
  public modelChange: EventEmitter<string> = new EventEmitter<string>();

  public async toggle() {
    this.listVisible = !this.listVisible;
  }

  public async select(item: string) {
    this.listVisible = false;

    if (this.model === item) {
      return;
    }

    this.model = item;
    this.modelChange?.emit(item);
  }

  isSelected(item: string): boolean {
    return this.model === item;
  }

  clear() {
    this.model = '';
    this.modelChange?.emit('');
  }

  public onFocusOutEvent() {
    // We need to give the click-event precedence, otherwise it will not be fired
    setTimeout(() => {
      this.listVisible = false;
    }, 200);
  }
}
