<div class="relative w-full">
  @let selectedRoleName = this.getSelectedRolesNames();
  <button
    role="button"
    class="flex h-12 w-full items-center justify-between gap-2 rounded-md border border-gray-300 p-2"
    (click)="toggleDropdown()"
    [title]="selectedRoleName | translate"
  >
    <p class="overflow-hidden text-ellipsis whitespace-nowrap text-left">
      {{ selectedRoleName | translate }}
    </p>
    <img
      src="/assets/icons/chevron-down.svg"
      width="22px"
      alt="chevron"
      [ngClass]="isToggled ? 'rotate-180' : 'rotate-0'"
    />
  </button>
  @if (isToggled) {
    <div
      class="custom-scrollbar absolute z-50 mt-1 flex max-h-60 w-full flex-col divide-y divide-gray-100 overflow-y-auto rounded-md border-2 border-gray-300 bg-white"
    >
      @for (role of roles; track role) {
        <label
          class="flex w-full cursor-pointer items-center justify-between gap-2 p-2 first:rounded-t-md last:rounded-b-md hover:bg-gray-100"
          [htmlFor]="role.id"
          [title]="role.name"
        >
          <p class="overflow-hidden text-ellipsis whitespace-nowrap text-left">
            {{ role.name }}
          </p>
          <input
            id="{{ role.id }}"
            [type]="allowMultiSelect ? 'checkbox' : 'radio'"
            [checked]="findRole(role)"
            (click)="onRoleChange(role)"
          />
        </label>
      }
      @if (roles?.length === 0) {
        <div class="p-2">
          <p
            class="overflow-hidden text-ellipsis whitespace-nowrap text-brand-blue-200"
          >
            {{ "rolesSelect.noRoles" | translate }}
          </p>
        </div>
      }
    </div>
  }
</div>
