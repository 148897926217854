import { Application } from '../../models/application.model';
import { Cluster } from '../../models/cluster.model';
import { Feature } from '../../models/feature.model';
import { SubFeature } from '../../models/subFeature.model';
import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    // Cluster
    case ActionTypes.LOAD_CLUSTERS: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_CLUSTERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        clusters: action.clusters,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_CLUSTERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        clusters: [],
      };
    }
    case ActionTypes.ADD_CLUSTER: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_CLUSTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.addCluster',
        clusters: [
          ...(state.clusters || []),
          {
            id: action.cluster.id,
            name: action.cluster.name,
            applications: [],
          } as Cluster,
        ],
      };
    }
    case ActionTypes.ADD_CLUSTER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_CLUSTER: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_CLUSTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.saveCluster',
        clusters: state.clusters?.map((cluster) =>
          cluster.id === action.cluster.id
            ? { ...cluster, name: action.cluster.name }
            : { ...cluster },
        ),
      };
    }
    case ActionTypes.SAVE_CLUSTER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_CLUSTER: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_CLUSTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.deleteCluster',
        clusters: state.clusters?.filter(
          (cluster) => cluster.id !== action.clusterId,
        ),
      };
    }
    case ActionTypes.DELETE_CLUSTER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    // Application
    case ActionTypes.ADD_APPLICATION: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_APPLICATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.addApplication',
        clusters: state.clusters?.map((cluster) => {
          if (cluster.id !== action.application.applicationClusterId) {
            return cluster;
          }
          return {
            ...cluster,
            applications: [
              ...(cluster.applications || []),
              {
                id: action.application.id,
                name: action.application.name,
                features: [] as Feature[],
              } as Application,
            ],
          };
        }),
      };
    }
    case ActionTypes.ADD_APPLICATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_APPLICATION: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_APPLICATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.saveApplication',
        clusters: saveApplication(state.clusters, action.application),
        features: renameFeatureKeys(state.features, action.application),
      };
    }
    case ActionTypes.SAVE_APPLICATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_APPLICATION: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_APPLICATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.deleteApplication',
        clusters: state.clusters?.map((cluster) => {
          if (cluster.id !== action.clusterId) {
            return cluster;
          }
          return {
            ...cluster,
            applications: cluster.applications?.filter(
              (application) => application.id !== action.applicationId,
            ),
          };
        }),
      };
    }
    case ActionTypes.DELETE_APPLICATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    // Roles
    case ActionTypes.LOAD_APPLICATIONS_ROLES: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_APPLICATIONS_ROLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        applicationsRoles: action.applicationsRoles,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_APPLICATIONS_ROLES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        applicationsRoles: [],
      };
    }
    case ActionTypes.LOAD_ROLES_BY_APPLICATION_ID: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_ROLES_BY_APPLICATION_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        roles: action.roles,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_ROLES_BY_APPLICATION_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        roles: [],
      };
    }
    case ActionTypes.ADD_ROLE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_ROLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.addRole',
        roles: action.role.isDefault
          ? [
              ...(state.roles?.map((role) => ({ ...role, isDefault: false })) ||
                []),
              action.role,
            ]
          : [...(state.roles || []), action.role],
      };
    }
    case ActionTypes.ADD_ROLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_ROLE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_ROLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.saveRole',
        roles: state.roles?.map((role) => {
          if (action.role.isDefault) {
            if (role.id === action.role.id) {
              return {
                ...role,
                name: action.role.name,
                description: action.role.description,
                isDefault: action.role.isDefault,
                key: action.role.key,
              };
            }
            return {
              ...role,
              isDefault: false,
            };
          }
          if (role.id === action.role.id) {
            return {
              ...role,
              name: action.role.name,
              description: action.role.description,
              isDefault: action.role.isDefault,
              key: action.role.key,
            };
          }
          return role;
        }),
      };
    }
    case ActionTypes.SAVE_ROLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_ROLE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.deleteRole',
        roles: state.roles?.filter((role) => role.id !== action.roleId),
      };
    }
    case ActionTypes.DELETE_ROLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    // Features
    case ActionTypes.LOAD_FEATURES_BY_APPLICATION_ID: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_FEATURES_BY_APPLICATION_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        features: action.features,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_FEATURES_BY_APPLICATION_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        features: [],
      };
    }
    case ActionTypes.ADD_FEATURE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_FEATURE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.addFeature',
        features: [
          ...(state.features || []),
          {
            id: action.feature.id,
            name: action.feature.name,
            key: action.feature.key,
            subFeatures: [] as SubFeature[],
          } as Feature,
        ],
      };
    }
    case ActionTypes.ADD_FEATURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_FEATURE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_FEATURE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.saveFeature',
        features: state.features?.map((feature) =>
          feature.id === action.feature.id
            ? {
                ...feature,
                name: action.feature.name,
                key: action.feature.key,
                subFeatures: feature.subFeatures.map((subFeature) => {
                  return {
                    ...subFeature,
                    key:
                      action.feature.key + '_' + subFeature.name.toLowerCase(),
                  };
                }),
              }
            : feature,
        ),
      };
    }
    case ActionTypes.SAVE_FEATURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_FEATURE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_FEATURE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.deleteFeature',
        features: state.features?.filter(
          (feature) => feature.id !== action.featureId,
        ),
      };
    }
    case ActionTypes.DELETE_FEATURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.ADD_SUB_FEATURE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_SUB_FEATURE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.addSubFeature',
        features: state.features?.map((feature) => {
          if (feature.id !== action.subFeature.featureId) {
            return feature;
          }
          return {
            ...feature,
            subFeatures: [
              ...(feature.subFeatures || []),
              {
                id: action.subFeature.id,
                name: action.subFeature.name,
                key: action.subFeature.key,
              } as SubFeature,
            ],
          };
        }),
      };
    }
    case ActionTypes.ADD_SUB_FEATURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_SUB_FEATURE: {
      return {
        ...state,
        isLoading: true,
        success: undefined,
        error: undefined,
      };
    }
    case ActionTypes.SAVE_SUB_FEATURE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.saveSubFeature',
        features: state.features?.map((feature) =>
          feature.id === action.subFeature.featureId
            ? {
                ...feature,
                subFeatures: feature.subFeatures?.map((subFeature) =>
                  subFeature.id === action.subFeature.id
                    ? {
                        ...subFeature,
                        name: action.subFeature.name,
                        key: action.subFeature.key,
                      }
                    : subFeature,
                ),
              }
            : feature,
        ),
      };
    }
    case ActionTypes.SAVE_SUB_FEATURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_SUB_FEATURE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_SUB_FEATURE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.deleteSubFeature',
        features: state.features?.map((feature) => {
          if (feature.id !== action.featureId) {
            return feature;
          }
          return {
            ...feature,
            subFeatures: feature.subFeatures?.filter(
              (subFeature) => subFeature.id !== action.subFeatureId,
            ),
          };
        }),
      };
    }
    case ActionTypes.DELETE_SUB_FEATURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.RESET_APPLICATION_STATE: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.RESET_APPLICATION_MESSAGES: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    default: {
      return state;
    }
  }
}

function saveApplication(
  clusters: Cluster[] | undefined,
  application: {
    id: number;
    clusterId: number;
    name: string;
    multiRolesAllowed: boolean;
    hasMachines: boolean;
  },
): Cluster[] {
  if (!clusters) {
    return [];
  }
  return clusters.map((cluster) => {
    if (cluster.id !== application.clusterId) {
      return cluster;
    }
    return {
      ...cluster,
      applications: cluster.applications?.map((app) => {
        if (app.id !== application.id) return app;

        return {
          ...app,
          name: application.name,
          multiRolesAllowed: application.multiRolesAllowed,
          hasMachines: application.hasMachines,
        };
      }),
    };
  });
}

function renameFeatureKeys(
  features: Feature[] | undefined,
  application: {
    id: number;
    clusterId: number;
    name: string;
    multiRolesAllowed: boolean;
    hasMachines: boolean;
  },
): Feature[] {
  if (!features) {
    return [];
  }
  const newAppKey = application.name.toLowerCase().replace(/ /g, '');

  return features.map((feature) => {
    const newFeatureKey =
      newAppKey + '_' + feature.name.toLowerCase().replace(/ /g, '');
    return {
      ...feature,
      key: newFeatureKey,
      subFeatures: feature.subFeatures.map((subFeature) => ({
        ...subFeature,
        key:
          newFeatureKey + '_' + subFeature.name.toLowerCase().replace(/ /g, ''),
      })),
    };
  });
}
