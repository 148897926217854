import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling,
} from '@angular/router';
import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import {
  ApplicationStoreModule,
  AuthStoreModule,
  MachineStoreModule,
  OrganizationStoreModule,
  UserStoreModule,
} from './core/store';
import {
  LoadLoggedUserPermissionsMiscAction,
  LoadUserAction,
} from './core/store/auth/actions';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withHashLocation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
    ),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    provideAuth0({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: environment.redirectUri,
        audience: environment.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.backendApiUrl}/api/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.audience,
              },
            },
          },
        ],
      },
    }),
    importProvidersFrom(
      BrowserAnimationsModule,
      BrowserModule,
      ApplicationStoreModule,
      AuthStoreModule,
      OrganizationStoreModule,
      UserStoreModule,
      MachineStoreModule,
      EffectsModule.forRoot({}),
      StoreModule.forRoot(
        {},
        {
          runtimeChecks: {
            strictActionImmutability: false,
            strictStateImmutability: false,
          },
        },
      ),
    ),
    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    provideAppInitializer(() => {
      const store = inject(Store);

      return new Promise<void>((resolve) => {
        store.dispatch(new LoadUserAction());
        store.dispatch(new LoadLoggedUserPermissionsMiscAction());
        resolve();
      });
    }),
  ],
};
