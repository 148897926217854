@if (
  (isLoading$ | async) === false &&
  (permissions$ | async) &&
  sortedOrganizations
) {
  <div
    [ngClass]="{
      'w-[200vw] pr-small': isMobilePortrait || isTablePortrait,
      'w-full': !(isMobilePortrait || isTablePortrait),
    }"
    class="bg-white"
  >
    <table
      class="w-full table-fixed divide-y divide-brand-gray-light font-brand-regular text-[13px] leading-[13px]"
    >
      <thead>
        <tr>
          <th
            class="w-1/4 pb-2 pr-3 text-left align-bottom text-[11px] uppercase leading-[11px] text-brand-blue sm:w-1/6"
          >
            @if ((permissions$ | async)?.canUpdateCompany && !isMobile) {
              <button
                (click)="addOrganization()"
                [ngClass]="{
                  'px-2 py-1 text-xs leading-4':
                    isMobilePortrait || isTablePortrait,
                  'p-2 text-sm leading-5': !(
                    isMobilePortrait || isTablePortrait
                  ),
                }"
                class="flex items-center gap-2 rounded-md border bg-white uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
              >
                <div>{{ "organizations.addCompanyButton" | translate }}</div>
                <div>
                  <img
                    [ngClass]="{
                      'h-4': isMobilePortrait,
                      'h-5': !isMobilePortrait,
                    }"
                    class="aspect-square"
                    src="/assets/icons/Reishauer_Icons-Add.svg"
                    alt="Icon add"
                  />
                </div>
              </button>
            }
            @if (!(permissions$ | async)?.canUpdateCompany || isMobile) {
              <div>
                {{ "organizations.addCompanyButton" | translate }}
              </div>
            }
          </th>
          <th
            class="flex w-full items-end pb-2 text-left text-[11px] uppercase leading-[11px] text-brand-blue"
          >
            @if (showUnits) {
              <div class="flex h-fit w-1/4 align-bottom">Unit name</div>
            }
            <div class="flex w-full">
              <div class="flex h-full w-full flex-col gap-y-4 truncate">
                <div
                  class="pl-[41px] font-brand-regular font-bold text-[#1F3D7D]"
                >
                  Academy
                </div>
                <div class="flex">
                  <span class="w-1/3 pl-[41px]">E-Learning</span>
                  <span class="w-1/3 pl-[41px]">Video Instructions</span>
                </div>
              </div>
              <div class="flex h-full w-full flex-col gap-y-4 truncate">
                <div
                  class="pl-[41px] font-brand-regular font-bold text-[#1F3D7D]"
                >
                  Monitoring
                </div>
                <div class="flex">
                  <span class="w-1/3 pl-[41px]">Argus</span>
                  <span class="w-1/3 pl-[41px]">Metrology</span>
                </div>
              </div>
            </div>
          </th>
          <th class="w-12"></th>
        </tr>
      </thead>
      <tbody
        class="divide-y divide-brand-gray-light align-middle text-brand-blue-800"
      >
        @for (organization of sortedOrganizations; track organization) {
          <tr
            [ngClass]="{
              'cursor-pointer': !(isMobile || isTablePortrait),
              'cursor-default': isMobile || isTablePortrait,
            }"
            class="hover:bg-brand-blue-lighter"
            (click)="navigateToOrganization(organization.id)"
          >
            <td>
              <div class="flex items-center">
                {{ organization.name }}
              </div>
            </td>
            <td
              [ngClass]="{
                'divide-y divide-brand-gray-light': showUnits,
                flex: !showUnits,
              }"
            >
              @if (showUnits) {
                @for (unit of organization.units; track unit) {
                  <div class="flex w-full">
                    <h3 class="flex h-8 w-1/4 items-center">
                      {{ unit.name }}
                    </h3>
                    <div class="flex w-full">
                      <div class="flex w-1/2 items-center">
                        <div class="flex w-1/3 gap-2">
                          <ng-container
                            [ngTemplateOutlet]="tplApplication"
                            [ngTemplateOutletContext]="{
                              invoiceIcon: getInvoiceIcon(
                                organization.id,
                                'Elearning',
                                unit.id
                              ),
                              isInvoiceSent: isInvoiceSent(
                                organization.id,
                                'Elearning',
                                unit.id
                              ),
                              applicationColor: getApplicationColor(
                                organization.id,
                                'Elearning',
                                unit.id
                              ),
                            }"
                          />
                        </div>
                        <div class="flex w-1/3 gap-2">
                          <ng-container
                            [ngTemplateOutlet]="tplApplication"
                            [ngTemplateOutletContext]="{
                              invoiceIcon: getInvoiceIcon(
                                organization.id,
                                'Video Instructions',
                                unit.id
                              ),
                              isInvoiceSent: isInvoiceSent(
                                organization.id,
                                'Video Instructions',
                                unit.id
                              ),
                              applicationColor: getApplicationColor(
                                organization.id,
                                'Video Instructions',
                                unit.id
                              ),
                            }"
                          />
                        </div>
                      </div>
                      <div class="flex w-1/2 items-center">
                        <div class="flex w-1/3 gap-2">
                          <ng-container
                            [ngTemplateOutlet]="tplApplication"
                            [ngTemplateOutletContext]="{
                              invoiceIcon: getInvoiceIcon(
                                organization.id,
                                'Argus',
                                unit.id
                              ),
                              isInvoiceSent: isInvoiceSent(
                                organization.id,
                                'Argus',
                                unit.id
                              ),
                              applicationColor: getApplicationColor(
                                organization.id,
                                'Argus',
                                unit.id
                              ),
                            }"
                          />
                        </div>
                        <div class="flex w-1/3 gap-2">
                          <ng-container
                            [ngTemplateOutlet]="tplApplication"
                            [ngTemplateOutletContext]="{
                              invoiceIcon: getInvoiceIcon(
                                organization.id,
                                'Metrology',
                                unit.id
                              ),
                              isInvoiceSent: isInvoiceSent(
                                organization.id,
                                'Metrology',
                                unit.id
                              ),
                              applicationColor: getApplicationColor(
                                organization.id,
                                'Metrology',
                                unit.id
                              ),
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              }
              @if (!showUnits) {
                <div class="flex w-1/2 items-center py-2">
                  <div class="flex w-1/3 gap-2">
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(
                          organization.id,
                          'Elearning'
                        ),
                        isInvoiceSent: isInvoiceSent(
                          organization.id,
                          'Elearning'
                        ),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Elearning'
                        ),
                      }"
                    />
                  </div>
                  <div class="flex w-1/3 gap-2">
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(
                          organization.id,
                          'Video Instructions'
                        ),
                        isInvoiceSent: isInvoiceSent(
                          organization.id,
                          'Video Instructions'
                        ),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Video Instructions'
                        ),
                      }"
                    />
                  </div>
                </div>
                <div class="flex w-1/2 items-center py-2">
                  <div class="flex w-1/3 gap-2">
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(organization.id, 'Argus'),
                        isInvoiceSent: isInvoiceSent(organization.id, 'Argus'),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Argus'
                        ),
                      }"
                    />
                  </div>
                  <div class="flex w-1/3 gap-2">
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(
                          organization.id,
                          'Metrology'
                        ),
                        isInvoiceSent: isInvoiceSent(
                          organization.id,
                          'Metrology'
                        ),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Metrology'
                        ),
                      }"
                    />
                  </div>
                </div>
              }
            </td>
            <td>
              <div class="flex items-center justify-end">
                @if ((permissions$ | async)?.canUpdateCompany && !isMobile) {
                  <button
                    class="flex items-center rounded-md border border-brand-gray-light bg-white px-2 py-1 hover:bg-brand-blue-light"
                    (click)="
                      $event.preventDefault();
                      $event.stopPropagation();
                      editOrganization(organization)
                    "
                  >
                    <img
                      class="fill-brand-primary h-5 w-auto"
                      src="/assets/icons/Reishauer_Icons-Edit.svg"
                      alt="Icon edit"
                    />
                  </button>
                }
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
}

<ng-template
  #tplApplication
  let-invoiceIcon="invoiceIcon"
  let-isInvoiceSent="isInvoiceSent"
  let-applicationColor="applicationColor"
>
  <img
    [src]="invoiceIcon"
    alt=""
    [ngClass]="{
      invisible: !isInvoiceSent || applicationColor === 'bg-brand-ok',
    }"
    class="h-6"
  />
  <div
    class="{{
      applicationColor
    }} w-6 h-6 p-1 items-center flex justify-center text-white"
  >
    @if (isInvoiceSent && applicationColor !== "bg-brand-ok") {
      <img
        src="/assets/icons/Reishauer_Icon_Checkmark.svg"
        alt="Checkmark"
        class="aspect-auto w-full"
      />
    }
  </div>
</ng-template>
