import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Machine } from 'src/app/core/models/machine.model';
import { OrganizationFolded } from 'src/app/core/models/organizationFolded.model';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'rh-admincenter-machines-table',
  imports: [CommonModule, FormsModule, DropdownComponent],
  templateUrl: './machines-table.component.html',
  styleUrl: './machines-table.component.scss',
})
export class MachinesTableComponent {
  private _machines: Machine[] = [];
  @Input({ required: true })
  set machines(machines: Machine[]) {
    this._machines = [...machines];
    this.filteredMachines = [...machines];

    if (this.global) {
      const compSet = new Set(
        machines.map((m) => m.organizationalContext.companyId),
      );
      this.filterCompanies = Array.from(compSet).map((c) => ({
        id: c,
        name:
          machines.find((m) => m.organizationalContext.companyId === c)
            ?.organizationalContext.companyName || '',
      }));
    }
  }
  get machines(): Machine[] {
    return this._machines;
  }
  @Input({ required: true }) machineTypes!: string[];
  @Input()
  set newMachine(machine: Machine | null) {
    if (!this.editingMachine) {
      this.editingMachine = machine;

      if (this.editingMachine) {
        this.machines = [this.editingMachine, ...(this.machines || [])];
      }
    }
  }
  @Input() global = false;
  @Input()
  set organizations(organizations: OrganizationFolded[]) {
    this.companies = organizations;
  }
  @Output() machineAdded = new EventEmitter<Machine>();
  @Output() machineUpdated = new EventEmitter<Machine>();
  @Output() machineDeleted = new EventEmitter<number>();

  private companies: OrganizationFolded[] = [];
  private units: { id: number; name: string }[] = [];

  private filterCompanies: { id: number; name: string }[] = [];
  private filterUnits?: { id: number; name: string }[];
  filterCompany: { id: number; name: string } | undefined;
  filterUnit: { id: number; name: string } | undefined;

  editingMachine: Machine | null = null;
  filteredMachines: Machine[] = [];

  get filterCompanyNames(): string[] {
    return this.filterCompanies.map((c) => c.name);
  }
  get filterUnitNames(): string[] {
    return this.filterUnits?.map((u) => u.name) || [];
  }
  get companyNames(): string[] {
    return this.companies.map((c) => c.name);
  }
  get unitNames(): string[] {
    return this.units.map((u) => u.name);
  }

  filterByCompany(name: string) {
    this.filterUnit = undefined;
    if (!name) {
      this.filterCompany = undefined;
      this.filteredMachines = this.machines;
      return;
    }
    this.filterCompany = this.filterCompanies.find((c) => c.name === name);

    this.filteredMachines = this.machines.filter(
      (m) => m.organizationalContext.companyId === this.filterCompany?.id,
    );

    const unitSet = new Set(
      this.filteredMachines.map((m) => m.organizationalContext.unitId),
    );
    this.filterUnits = Array.from(unitSet).map((u) => ({
      id: u,
      name:
        this.filteredMachines.find((m) => m.organizationalContext.unitId === u)
          ?.organizationalContext.unitName || '',
    }));
  }

  filterByUnit(name: string) {
    if (!this.filterCompany) {
      return;
    }

    if (!name) {
      this.filterUnit = undefined;
      this.filterByCompany(this.filterCompany?.name || '');
      return;
    }
    this.filterUnit = this.filterUnits?.find((u) => u.name === name);

    this.filteredMachines = this.machines.filter(
      (m) => m.organizationalContext.unitId === this.filterUnit?.id,
    );
  }

  selectCompany(company: string) {
    if (!this.editingMachine) {
      return;
    }

    const comp = this.companies.find((c) => c.name === company);
    this.units = this.companies.find((o) => o.name === company)?.units || [];

    this.editingMachine = {
      ...this.editingMachine,
      organizationalContext: {
        ...this.editingMachine.organizationalContext,
        companyName: comp?.name || '',
        companyId: comp?.id || 0,
        unitName: this.units[0]?.name || '',
        unitId: this.units[0]?.id || 0,
      },
    };
    console.log(this.editingMachine);
  }

  selectUnit(unit: string) {
    if (!this.editingMachine) {
      return;
    }

    this.editingMachine.organizationalContext.unitName = unit;
    this.editingMachine.organizationalContext.unitId =
      this.units.find((u) => u.name === unit)?.id || 0;
  }

  getMachineStatus(machine: Machine): string {
    const currentDate = new Date();
    const argus = new Date(machine.argusLicenseValidTo);
    const maas = new Date(machine.maasLicenseValidTo);

    if (
      (argus &&
        argus.getTime() - currentDate.getTime() <= 30 * 24 * 60 * 60 * 1000) ||
      (maas &&
        maas.getTime() - currentDate.getTime() <= 30 * 24 * 60 * 60 * 1000)
    ) {
      return 'DANGER';
    } else if (
      (argus &&
        argus.getTime() - currentDate.getTime() <= 90 * 24 * 60 * 60 * 1000) ||
      (maas &&
        maas.getTime() - currentDate.getTime() <= 90 * 24 * 60 * 60 * 1000)
    ) {
      return 'WARNING';
    } else {
      return 'OK';
    }
  }

  onMachineUpdated(machine: Machine) {
    this.machineUpdated.emit(machine);
  }

  enterEditMode(machine: Machine) {
    if (this.editingMachine) {
      return;
    }
    this.editingMachine = { ...machine };
    this.units =
      this.companies.find(
        (c) => c.id === this.editingMachine!.organizationalContext.companyId,
      )?.units || [];
  }

  cancelEdit() {
    if (this.editingMachine?.id === 0) {
      this.machines = this.machines?.filter(
        (c) => c.id !== this.editingMachine?.id,
      );
    }
    this.editingMachine = null;
  }

  updateIsMobile() {
    if (!this.editingMachine) {
      return;
    }
    this.editingMachine.isMobile = !this.editingMachine.isMobile;
  }

  updateArgusInvoice() {
    if (!this.editingMachine) {
      return;
    }
    this.editingMachine.argusInvoice = !this.editingMachine.argusInvoice;
  }

  updateMaasInvoice() {
    if (!this.editingMachine) {
      return;
    }
    this.editingMachine.maasInvoice = !this.editingMachine.maasInvoice;
  }

  editArgusValidTo(validTo: Event) {
    if (!this.editingMachine) {
      return;
    }
    const validToString = (validTo.target as HTMLInputElement).value;

    this.editingMachine.argusLicenseValidTo = validToString;
  }

  editMaasValidTo(validTo: Event) {
    if (!this.editingMachine) {
      return;
    }
    const validToString = (validTo.target as HTMLInputElement).value;

    this.editingMachine.maasLicenseValidTo = validToString;
  }

  saveMachine() {
    if (!this.editingMachine) {
      return;
    }

    if (
      !this.editingMachine.machineNumber ||
      !this.editingMachine.machineAlias ||
      !this.editingMachine.type
    ) {
      return;
    }

    if (this.editingMachine.id === 0) {
      this.machineAdded.emit(this.editingMachine);
    } else {
      this.machineUpdated.emit(this.editingMachine);
    }
    this.editingMachine = null;
  }

  deleteMachine(machine: Machine) {
    this.machineDeleted.emit(machine.id);
  }
}
