import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable, Subscription } from 'rxjs';
import { LoggedUserPermissionsMisc } from './core/models/loggedUserPermissionsMisc.model';
import { State } from './core/store/state';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { UserMenuComponent } from './layout/user-menu/user-menu.component';
import { VerticalMenuComponent } from './layout/vertical-menu/vertical-menu.component';
import { LoadingComponent } from './shared/components/loading/loading.component';

@Component({
  selector: 'rh-admincenter-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    SidebarComponent,
    VerticalMenuComponent,
    UserMenuComponent,
    HeaderComponent,
    LoadingComponent,
    CommonModule,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  loggedUserPermissionsMisc$?: Observable<LoggedUserPermissionsMisc>;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
  }

  isLoading$!: Observable<boolean>;
  breadcrumbs$!: Observable<
    {
      name: string;
      url?: string | undefined;
    }[]
  >;
  language$!: Observable<string>;

  screenWidth = 0;
  sidebarOpen = false;
  userMenuOpen = false;
  verticalMenuOpen = false;
  backgroundColor = 'bg-white';

  enableVerticalMenu = true;

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  constructor(
    private store$: Store<State>,
    private responsive: BreakpointObserver,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];
        }),
    );

    this.isLoading$ = this.store$.select((state) => {
      return state.Auth.isLoading as boolean;
    });

    this.loggedUserPermissionsMisc$ = this.store$.select((state) => {
      return state.Auth.permissions as LoggedUserPermissionsMisc;
    });

    this.language$ = this.store$.select((state) => {
      return state.Auth.user?.language as string;
    });

    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd,
          ),
        )
        .subscribe((val) => {
          if (val.url === '/' || val.url.includes('/organizations')) {
            this.backgroundColor = 'bg-white';
            this.enableVerticalMenu = true;
            this.verticalMenuOpen = true;
          } else if (val.url.includes('/applications')) {
            this.backgroundColor = 'bg-brand-gray-200';
            this.enableVerticalMenu = true;
            this.verticalMenuOpen = true;
          } else if (
            val.url.includes('/users') ||
            val.url.includes('/machines')
          ) {
            this.backgroundColor = 'bg-white';
            this.enableVerticalMenu = false;
            this.verticalMenuOpen = false;
          } else {
            this.backgroundColor = 'bg-brand-gray-200';
            this.enableVerticalMenu = false;
            this.verticalMenuOpen = false;
          }
        }),
    );
  }

  toggleBackgroundEvent(): void {
    this.backgroundColor = 'bg-gray-500';
  }

  toggleSidebarEvent(): void {
    this.sidebarOpen = !this.sidebarOpen;
  }

  toggleUserMenuEvent(): void {
    this.userMenuOpen = !this.userMenuOpen;
  }

  toggleVerticalMenuEvent(): void {
    this.verticalMenuOpen = !this.verticalMenuOpen;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
