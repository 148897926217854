import { Machine } from '../../models/machine.model';
import { OrganizationProxies } from '../../models/OrganizationProxies.model';

export interface State {
  machines?: Machine[];
  isLoading: boolean;
  error?: string;
  types?: string[];
  proxies?: string[];
  organizationProxies?: OrganizationProxies;
}

export const initialState: State = {
  isLoading: true,
};
