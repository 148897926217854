import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { MachineService } from '../../services/machine/machine.service';
import { OrganizationService } from '../../services/organization/organization.service';
import {
  ActionTypes,
  AddDepartmentAction,
  AddDepartmentFailureAction,
  AddDepartmentSuccessAction,
  AddLicenceAction,
  AddLicenceFailureAction,
  AddLicenceSuccessAction,
  AddMemberAction,
  AddMemberFailureAction,
  AddMemberSuccessAction,
  AddOrganizationAction,
  AddOrganizationFailureAction,
  AddOrganizationSuccessAction,
  AddUnitAction,
  AddUnitFailureAction,
  AddUnitMachineAction,
  AddUnitMachineFailureAction,
  AddUnitMachineSuccessAction,
  AddUnitSuccessAction,
  BlockUserAction,
  BlockUserFailureAction,
  BlockUserSuccessAction,
  ChangePasswordAction,
  ChangePasswordFailureAction,
  ChangePasswordSuccessAction,
  DeleteDepartmentAction,
  DeleteDepartmentFailureAction,
  DeleteDepartmentSuccessAction,
  DeleteLicenceAction,
  DeleteLicenceFailureAction,
  DeleteLicenceSuccessAction,
  DeleteMemberAction,
  DeleteMemberFailureAction,
  DeleteMemberSuccessAction,
  DeleteUnitMachineAction,
  DeleteUnitMachineFailureAction,
  DeleteUnitMachineSuccessAction,
  EditLicencesAction,
  EditLicencesFailureAction,
  EditLicencesSuccessAction,
  LoadDepartmentsAction,
  LoadDepartmentsFailureAction,
  LoadDepartmentsSuccessAction,
  LoadOrganizationAction,
  LoadOrganizationFailureAction,
  LoadOrganizationSuccessAction,
  LoadOrganizationsAction,
  LoadOrganizationsFailureAction,
  LoadOrganizationsSuccessAction,
  LoadUnitUsersAction,
  LoadUnitUsersFailureAction,
  LoadUnitUsersSuccessAction,
  LoadUnitsAction,
  LoadUnitsFailureAction,
  LoadUnitsSuccessAction,
  Reset2faAction,
  Reset2faFailureAction,
  Reset2faSuccessAction,
  SaveMemberAction,
  SaveMemberFailureAction,
  SaveMemberRolesAction,
  SaveMemberRolesFailureAction,
  SaveMemberRolesSuccessAction,
  SaveMemberSuccessAction,
  SaveOrganizationAction,
  SaveOrganizationFailureAction,
  SaveOrganizationSuccessAction,
  SaveUnitAction,
  SaveUnitFailureAction,
  SaveUnitSuccessAction,
  UnblockUserAction,
  UnblockUserFailureAction,
  UnblockUserSuccessAction,
  UpdateUnitMachineAction,
  UpdateUnitMachineFailureAction,
  UpdateUnitMachineSuccessAction,
} from './actions';

@Injectable()
export class OrganizationStoreEffects {
  private machineService = inject(MachineService);
  private actions$ = inject(Actions);
  private organizationService = inject(OrganizationService);
  private snackbar = inject(SnackbarService);

  loadOrganizationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadOrganizationsAction>(ActionTypes.LOAD_ORGANIZATIONS),
      exhaustMap(() =>
        this.organizationService.GetOrganizations().pipe(
          map((items) => new LoadOrganizationsSuccessAction(items)),
          catchError((error) =>
            observableOf(
              new LoadOrganizationsFailureAction(error.error.detail),
            ),
          ),
        ),
      ),
    ),
  );

  loadOrganizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadOrganizationAction>(ActionTypes.LOAD_ORGANIZATION),
      exhaustMap((action) =>
        this.organizationService.GetOrganization(action.organizationId).pipe(
          map((items) => new LoadOrganizationSuccessAction(items)),
          catchError((error) =>
            observableOf(new LoadOrganizationFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addOrganizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddOrganizationAction>(ActionTypes.ADD_ORGANIZATION),
      exhaustMap((action) =>
        this.organizationService.AddOrganization(action.organizationName).pipe(
          map(() => new AddOrganizationSuccessAction()),
          catchError((error) =>
            observableOf(new AddOrganizationFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addOrganizationSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddOrganizationSuccessAction>(
        ActionTypes.ADD_ORGANIZATION_SUCCESS,
      ),
      exhaustMap(() => {
        return [new LoadOrganizationsAction()];
      }),
    ),
  );

  saveOrganizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveOrganizationAction>(ActionTypes.SAVE_ORGANIZATION),
      exhaustMap((action) =>
        this.organizationService.SaveOrganization(action.organization).pipe(
          map((item) => new SaveOrganizationSuccessAction(item)),
          catchError((error) =>
            observableOf(new SaveOrganizationFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  loadUnitsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadUnitsAction>(ActionTypes.LOAD_UNITS),
      exhaustMap((action) =>
        this.organizationService.GetUnits(action.organizationId).pipe(
          map((items) => new LoadUnitsSuccessAction(items)),
          catchError((error) =>
            observableOf(new LoadUnitsFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addUnitEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddUnitAction>(ActionTypes.ADD_UNIT),
      exhaustMap((action) =>
        this.organizationService
          .AddUnit(action.organizationId, action.unitName)
          .pipe(
            map(() => new AddUnitSuccessAction(action.organizationId)),
            catchError((error) =>
              observableOf(new AddUnitFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  addUnitSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddUnitSuccessAction>(ActionTypes.ADD_UNIT_SUCCESS),
      exhaustMap((action) => {
        return [
          new LoadUnitsAction(action.organizationId),
          new LoadUnitUsersAction(action.organizationId),
        ];
      }),
    ),
  );

  saveUnitEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveUnitAction>(ActionTypes.SAVE_UNIT),
      exhaustMap((action) =>
        this.organizationService.SaveUnit(action.unit).pipe(
          map((item) => new SaveUnitSuccessAction(item)),
          catchError((error) =>
            observableOf(new SaveUnitFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addLicenceEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddLicenceAction>(ActionTypes.ADD_LICENCE),
      exhaustMap((action) =>
        this.organizationService
          .AddLicence(
            action.unitId,
            action.validTo,
            action.featureId,
            action.applicationId,
          )
          .pipe(
            map((item) => new AddLicenceSuccessAction(item)),
            catchError((error) =>
              observableOf(new AddLicenceFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  editLicencesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EditLicencesAction>(ActionTypes.EDIT_LICENCES),
      exhaustMap((action) =>
        this.organizationService
          .EditLicences(
            action.unitId,
            action.validTo,
            action.isInvoiceSent,
            action.applicationId,
          )
          .pipe(
            map((item) => new EditLicencesSuccessAction(item)),
            catchError((error) =>
              observableOf(new EditLicencesFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  deleteLicenceEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteLicenceAction>(ActionTypes.DELETE_LICENCE),
      exhaustMap((action) =>
        this.organizationService.DeleteLicence(action.licenceId).pipe(
          exhaustMap(() => [
            new DeleteLicenceSuccessAction(
              action.applicationId,
              action.licenceId,
            ),
          ]),
          catchError((error) =>
            observableOf(new DeleteLicenceFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addMemberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddMemberAction>(ActionTypes.ADD_MEMBER),
      exhaustMap((action) =>
        this.organizationService.AddMember(action.unitId, action.member).pipe(
          map((item) => new AddMemberSuccessAction(item)),
          catchError((error) =>
            observableOf(new AddMemberFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  deleteMemberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteMemberAction>(ActionTypes.DELETE_MEMBER),
      exhaustMap((action) =>
        this.organizationService
          .DeleteMember(action.unitId, action.memberId)
          .pipe(
            exhaustMap(() => [
              new DeleteMemberSuccessAction(action.unitId, action.memberId),
            ]),
            catchError((error) =>
              observableOf(new DeleteMemberFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  saveMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMemberAction>(ActionTypes.SAVE_MEMBER),
      exhaustMap((action) =>
        this.organizationService.SaveMember(action.unitId, action.member).pipe(
          map((item) => new SaveMemberSuccessAction(item)),
          catchError((error) =>
            observableOf(new SaveMemberFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  saveMemberRolesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMemberRolesAction>(ActionTypes.SAVE_MEMBER_ROLES),
      exhaustMap((action) =>
        this.organizationService
          .SaveMemberRoles(
            action.unitId,
            action.userId,
            action.applicationId,
            action.roles,
          )
          .pipe(
            map(
              (item) =>
                new SaveMemberRolesSuccessAction(
                  item.unitId,
                  item.userId,
                  item.applicationId,
                  item.roles,
                ),
            ),
            catchError((error) =>
              observableOf(
                new SaveMemberRolesFailureAction(
                  action.unitId,
                  action.userId,
                  error.error.detail,
                ),
              ),
            ),
          ),
      ),
    ),
  );

  loadUnitUsersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadUnitUsersAction>(ActionTypes.LOAD_UNIT_USERS),
      exhaustMap((action) =>
        this.organizationService
          .GetUsersFromOrganization(action.companyId)
          .pipe(
            map((items) => new LoadUnitUsersSuccessAction(items)),
            catchError((error) =>
              observableOf(new LoadUnitUsersFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  blockUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BlockUserAction>(ActionTypes.BLOCK_USER_ACTION),
      exhaustMap((action) =>
        this.organizationService.BlockUser(action.unitId, action.userId).pipe(
          map(() => new BlockUserSuccessAction(action.unitId, action.userId)),
          catchError((error) =>
            observableOf(new BlockUserFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  unblockUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnblockUserAction>(ActionTypes.UNBLOCK_USER_ACTION),
      exhaustMap((action) =>
        this.organizationService.UnblockUser(action.unitId, action.userId).pipe(
          map(() => new UnblockUserSuccessAction(action.unitId, action.userId)),
          catchError((error) =>
            observableOf(new UnblockUserFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  reset2faEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Reset2faAction>(ActionTypes.RESET_2FA_ACTION),
      exhaustMap((action) =>
        this.organizationService.Reset2fa(action.unitId, action.userId).pipe(
          map(() => new Reset2faSuccessAction()),
          catchError((error) =>
            observableOf(new Reset2faFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  changePasswordEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangePasswordAction>(ActionTypes.CHANGE_PASSWORD_ACTION),
      exhaustMap((action) =>
        this.organizationService
          .ChangePassword(action.unitId, action.userId)
          .pipe(
            map(() => new ChangePasswordSuccessAction()),
            catchError((error) =>
              observableOf(new ChangePasswordFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  loadDepartmentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadDepartmentsAction>(ActionTypes.LOAD_DEPARTMENTS),
      exhaustMap((action) =>
        this.organizationService.GetDepartments(action.organizationId).pipe(
          map((items) => new LoadDepartmentsSuccessAction(items)),
          catchError((error) =>
            observableOf(new LoadDepartmentsFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addDepartmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddDepartmentAction>(ActionTypes.ADD_DEPARTMENT),
      exhaustMap((action) =>
        this.organizationService
          .AddDepartment(action.organizationId, action.departmentName)
          .pipe(
            map((item) => new AddDepartmentSuccessAction(item)),
            catchError((error) =>
              observableOf(new AddDepartmentFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  deleteDepartmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteDepartmentAction>(ActionTypes.DELETE_DEPARTMENT),
      exhaustMap((action) =>
        this.organizationService.DeleteDepartment(action.departmentId).pipe(
          exhaustMap(() => [new DeleteDepartmentSuccessAction()]),
          catchError((error) =>
            observableOf(new DeleteDepartmentFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addUnitMachineEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddUnitMachineAction>(ActionTypes.ADD_UNIT_MACHINE),
      exhaustMap((action) =>
        this.machineService.AddMachine(action.machine).pipe(
          map((machine) => new AddUnitMachineSuccessAction(machine)),
          catchError((error) =>
            of(new AddUnitMachineFailureAction(error.error)),
          ),
        ),
      ),
    ),
  );

  addUnitMachineSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddUnitMachineSuccessAction>(
          ActionTypes.ADD_UNIT_MACHINE_SUCCESS,
        ),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: 'success.addMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  addUnitMachineFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddUnitMachineFailureAction>(
          ActionTypes.ADD_UNIT_MACHINE_FAILURE,
        ),
        tap((action) => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: 'error.addMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  updateUnitMachineEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateUnitMachineAction>(ActionTypes.UPDATE_UNIT_MACHINE),
      exhaustMap((action) =>
        this.machineService.UpdateMachine(action.machine).pipe(
          map(() => new UpdateUnitMachineSuccessAction(action.machine)),
          catchError((error) =>
            of(new UpdateUnitMachineFailureAction(error.error)),
          ),
        ),
      ),
    ),
  );

  updateUnitMachineSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateUnitMachineSuccessAction>(
          ActionTypes.UPDATE_UNIT_MACHINE_SUCCESS,
        ),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: 'success.saveMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  updateUnitMachineFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateUnitMachineFailureAction>(
          ActionTypes.UPDATE_UNIT_MACHINE_FAILURE,
        ),
        tap((action) => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: 'error.saveMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  deleteUnitMachineEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteUnitMachineAction>(ActionTypes.DELETE_UNIT_MACHINE),
      exhaustMap((action) =>
        this.machineService.DeleteMachine(action.machineId).pipe(
          map(
            () =>
              new DeleteUnitMachineSuccessAction(
                action.machineId,
                action.unitId,
              ),
          ),
          catchError((error) =>
            of(new DeleteUnitMachineFailureAction(error.error)),
          ),
        ),
      ),
    ),
  );

  deleteUnitMachineSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteUnitMachineSuccessAction>(
          ActionTypes.DELETE_UNIT_MACHINE_SUCCESS,
        ),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: 'success.deleteMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  deleteUnitMachineFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteUnitMachineFailureAction>(
          ActionTypes.DELETE_UNIT_MACHINE_FAILURE,
        ),
        tap((action) => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: 'error.deleteMachine',
          });
        }),
      ),
    { dispatch: false },
  );
}
