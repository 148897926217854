<div class="flex w-full flex-col gap-4">
  @if (global) {
    <div class="flex w-fit gap-4">
      <div class="w-44">
        <rh-dropdown
          [items]="filterCompanyNames"
          [model]="filterCompany?.name"
          (modelChange)="filterByCompany($event)"
          title="Company"
          placeholder="Select company"
          [invalidIfEmpty]="false"
          [clearable]="true"
        />
      </div>
      @if (filterCompany) {
        <div class="w-44">
          <rh-dropdown
            [items]="filterUnitNames"
            [model]="filterUnit?.name"
            (modelChange)="filterByUnit($event)"
            title="Unit"
            placeholder="Select unit"
            [invalidIfEmpty]="false"
            [clearable]="true"
          />
        </div>
      }
    </div>
  }

  <ol
    class="grid w-full gap-x-2 divide-y overflow-x-auto"
    [ngClass]="global ? 'grid-global' : 'grid-local'"
  >
    <li
      class="col-span-full grid grid-cols-subgrid text-left font-brand-thin text-sm font-bold text-brand-blue-360"
    >
      <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="M-Nr.">
        M-Nr.
      </p>
      <p
        class="overflow-hidden text-ellipsis whitespace-nowrap"
        title="Client Alias"
      >
        Client Alias
      </p>
      @if (global) {
        <p
          class="overflow-hidden text-ellipsis whitespace-nowrap"
          title="Company"
        >
          Company
        </p>
        <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="Unit">
          Unit
        </p>
      }
      <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="Type">
        Type
      </p>
      <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="IP">
        IP
      </p>
      <p
        class="overflow-hidden text-ellipsis whitespace-nowrap"
        title="Proxy-ID"
      >
        Proxy-ID
      </p>
      <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="Mobile">
        Mobile
      </p>
      <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="Argus">
        Argus
      </p>
      <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="MaaS">
        MaaS
      </p>
      <p class="overflow-hidden text-ellipsis whitespace-nowrap" title="Status">
        Status
      </p>
      <p></p>
    </li>
    @for (machine of filteredMachines; track machine.id) {
      @let editMode =
        (editingMachine && machine.id === editingMachine.id) || false;
      @let machineModel = editMode ? editingMachine! : machine;
      <li
        [ngClass]="{
          'bg-brand-blue-550 text-white': editMode,
          'hover:bg-brand-blue-lighter': !editMode,
        }"
        class="col-span-full grid grid-cols-subgrid items-center py-1 text-base text-brand-blue-800"
      >
        <input
          [disabled]="!editMode"
          type="number"
          [(ngModel)]="machineModel.machineNumber"
          [title]="machineModel.machineNumber"
          [ngClass]="{
            border: editMode,
            'border-red-500': !machineModel.machineNumber && editMode,
          }"
          class="h-8 w-full min-w-12 max-w-16 text-ellipsis border border-brand-blue-240 bg-inherit focus:outline-none"
        />

        <input
          [disabled]="!editMode"
          type="text"
          [(ngModel)]="machineModel.machineAlias"
          [title]="machineModel.machineAlias"
          [ngClass]="{
            border: editMode,
            'border-red-500': !machineModel.machineAlias && editMode,
          }"
          class="h-8 w-full min-w-12 max-w-40 text-ellipsis border border-brand-blue-240 bg-inherit focus:outline-none"
        />

        @if (global) {
          <rh-dropdown
            [blueMode]="editMode"
            [disabled]="!editMode"
            [invalidIfEmpty]="true"
            [items]="companyNames"
            [model]="machineModel.organizationalContext.companyName"
            (modelChange)="selectCompany($event)"
            class="w-full min-w-14 max-w-40"
          />

          <rh-dropdown
            [blueMode]="editMode"
            [disabled]="!editMode"
            [invalidIfEmpty]="true"
            [items]="unitNames"
            [model]="machineModel.organizationalContext.unitName"
            (modelChange)="selectUnit($event)"
            class="w-full min-w-14 max-w-40"
          />
        }

        <rh-dropdown
          [blueMode]="editMode"
          [disabled]="!editMode"
          [invalidIfEmpty]="true"
          [items]="machineTypes"
          [(model)]="machineModel.type"
          class="w-full min-w-14 max-w-36"
        />

        <input
          [disabled]="!editMode"
          type="text"
          [(ngModel)]="machineModel.ipAddress"
          [title]="machineModel.ipAddress || ''"
          class="h-8 w-full min-w-12 max-w-32 text-ellipsis border border-brand-blue-240 bg-transparent focus:bg-transparent focus:outline-none"
          [ngClass]="{ border: editMode }"
        />

        <input
          [disabled]="!editMode"
          type="text"
          [(ngModel)]="machineModel.proxyId"
          [title]="machineModel.proxyId || ''"
          class="h-8 w-full min-w-12 max-w-40 text-ellipsis border border-brand-blue-240 bg-transparent focus:bg-transparent focus:outline-none"
          [ngClass]="{ border: editMode }"
        />

        <div class="w-full min-w-8 max-w-10">
          <button
            [disabled]="!editMode"
            (click)="updateIsMobile()"
            class="flex aspect-square w-6 shrink-0 items-center border border-brand-blue-240 bg-white p-1 focus:outline-none focus:ring-0 has-[img]:border-none has-[img]:bg-brand-warning"
          >
            @if (machineModel.isMobile) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </button>
        </div>

        <div class="flex w-44 items-center gap-2">
          <input
            [disabled]="!editMode"
            type="date"
            id="startDate"
            class="h-8 w-32 rounded-md border border-brand-blue-240 bg-inherit px-2 text-left text-base focus:outline-none focus:ring-0"
            [value]="machineModel.argusLicenseValidTo | date: 'yyyy-MM-dd'"
            (change)="editArgusValidTo($event)"
            required
          />

          <button
            [disabled]="!editMode"
            (click)="updateArgusInvoice()"
            class="flex h-6 w-6 shrink-0 items-center border border-brand-blue-240 bg-white p-1 focus:outline-none focus:ring-0 has-[img]:border-none has-[img]:bg-brand-warning"
          >
            @if (machineModel.argusInvoice) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </button>
        </div>

        <div class="flex w-44 items-center gap-2">
          <input
            [disabled]="!editMode"
            type="date"
            id="startDate"
            class="h-8 w-32 rounded-md border border-brand-blue-240 bg-inherit px-2 text-left text-base focus:outline-none focus:ring-0"
            [value]="machineModel.maasLicenseValidTo | date: 'yyyy-MM-dd'"
            (change)="editMaasValidTo($event)"
            required
          />

          <button
            [disabled]="!editMode"
            (click)="updateMaasInvoice()"
            class="flex h-6 w-6 shrink-0 items-center border border-brand-blue-240 bg-white p-1 focus:outline-none focus:ring-0 has-[img]:border-none has-[img]:bg-brand-warning"
          >
            @if (machineModel.maasInvoice) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </button>
        </div>

        <div class="flex items-center justify-center">
          @switch (getMachineStatus(machineModel)) {
            @case ("DANGER") {
              <div
                class="aspect-square w-6 bg-brand-danger"
                title="DANGER"
              ></div>
            }
            @case ("WARNING") {
              <div
                class="aspect-square w-6 bg-brand-warning"
                title="WARNING"
              ></div>
            }
            @case ("OK") {
              <div class="aspect-square w-6 bg-brand-ok" title="OK"></div>
            }
            @default {
              <div
                class="aspect-square w-6 bg-brand-default"
                title="DEFAULT"
              ></div>
            }
          }
        </div>

        <div class="flex gap-2">
          @if (editMode) {
            <button
              (click)="cancelEdit()"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light p-1 px-1 hover:bg-brand-blue-800"
            >
              <img
                class="aspect-auto w-[22px]"
                src="/assets/icons/Close.svg"
                alt="Cancel"
              />
            </button>
            <button
              (click)="saveMachine()"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light p-1 px-1 hover:bg-brand-blue-800"
            >
              <img
                class="aspect-auto w-[22px]"
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Save"
              />
            </button>
          } @else {
            <button
              (click)="enterEditMode(machine)"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white p-1 px-1 hover:bg-brand-blue-light"
            >
              <img
                src="/assets/icons/Reishauer_Icon_Edit.svg"
                alt="Edit Machine"
                class="aspect-auto w-[22px] fill-brand-blue-900"
              />
            </button>
            <button
              (click)="deleteMachine(machine)"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white p-1 px-1 hover:bg-brand-blue-light"
            >
              <img
                src="/assets/icon-delete.svg"
                alt="Delete Machine"
                class="aspect-auto w-[22px] fill-brand-red"
              />
            </button>
          }
        </div>
      </li>
    }
  </ol>
</div>
