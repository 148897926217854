import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { MachineService } from '../../services/machine/machine.service';
import {
  ActionTypes,
  AddMachineAction,
  AddMachineFailureAction,
  AddMachineSuccessAction,
  DeleteMachineAction,
  DeleteMachineFailureAction,
  DeleteMachineSuccessAction,
  GetMachineTypesAction,
  GetMachineTypesFailureAction,
  GetMachineTypesSuccessAction,
  GetProxiesAction,
  GetProxiesByCompanyAction,
  GetProxiesByCompanyFailureAction,
  GetProxiesByCompanySuccessAction,
  GetProxiesFailureAction,
  GetProxiesSuccessAction,
  LoadMachinesAction,
  LoadMachinesFailureAction,
  LoadMachinesSuccessAction,
  UpdateMachineAction,
  UpdateMachineFailureAction,
  UpdateMachineSuccessAction,
} from './actions';

@Injectable()
export class MachineStoreEffects {
  private machineService = inject(MachineService);
  private actions$ = inject(Actions);
  private snackbar = inject(SnackbarService);

  loadMachinesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadMachinesAction>(ActionTypes.LOAD_MACHINES),
      exhaustMap(() =>
        this.machineService.GetMachines().pipe(
          map((items) => new LoadMachinesSuccessAction(items)),
          catchError((error) => of(new LoadMachinesFailureAction(error.error))),
        ),
      ),
    ),
  );

  addMachinesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddMachineAction>(ActionTypes.ADD_MACHINE),
      exhaustMap((action) =>
        this.machineService
          .AddMachine({
            ...action.machine,
            unitId: action.machine.organizationalContext.unitId,
          })
          .pipe(
            map((item) => new AddMachineSuccessAction(item)),
            catchError((error) => of(new AddMachineFailureAction(error.error))),
          ),
      ),
    ),
  );

  addMachineSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddMachineSuccessAction>(ActionTypes.ADD_MACHINE_SUCCESS),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: 'success.addMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  addMachineFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddMachineFailureAction>(ActionTypes.ADD_MACHINE_FAILURE),
        tap((action) => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: 'error.addMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  updateMachineEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMachineAction>(ActionTypes.UPDATE_MACHINE),
      exhaustMap((action) =>
        this.machineService
          .UpdateMachine({
            ...action.machine,
            unitId: action.machine.organizationalContext.unitId,
          })
          .pipe(
            map(() => new UpdateMachineSuccessAction(action.machine)),
            catchError((error) =>
              of(new UpdateMachineFailureAction(error.error)),
            ),
          ),
      ),
    ),
  );

  updateMachineSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateMachineSuccessAction>(ActionTypes.UPDATE_MACHINE_SUCCESS),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: 'success.saveMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  updateMachineFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateMachineFailureAction>(ActionTypes.UPDATE_MACHINE_FAILURE),
        tap((action) => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: 'error.saveMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  deleteMachineEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteMachineAction>(ActionTypes.DELETE_MACHINE),
      exhaustMap((action) =>
        this.machineService.DeleteMachine(action.machineId).pipe(
          map(() => new DeleteMachineSuccessAction(action.machineId)),
          catchError((error) =>
            of(new DeleteMachineFailureAction(error.error)),
          ),
        ),
      ),
    ),
  );

  deleteMachineSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteMachineSuccessAction>(ActionTypes.DELETE_MACHINE_SUCCESS),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: 'success.deleteMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  deleteMachineFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteMachineFailureAction>(ActionTypes.DELETE_MACHINE_FAILURE),
        tap((action) => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: 'error.deleteMachine',
          });
        }),
      ),
    { dispatch: false },
  );

  getMachineTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetMachineTypesAction>(ActionTypes.GET_MACHINE_TYPES),
      exhaustMap(() =>
        this.machineService.GetMachineTypes().pipe(
          map((items) => new GetMachineTypesSuccessAction(items)),
          catchError((error) =>
            of(new GetMachineTypesFailureAction(error.error)),
          ),
        ),
      ),
    ),
  );

  getProxiesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProxiesAction>(ActionTypes.GET_PROXIES),
      exhaustMap(() =>
        this.machineService.GetProxies().pipe(
          map((items) => new GetProxiesSuccessAction(items)),
          catchError((error) => of(new GetProxiesFailureAction(error.error))),
        ),
      ),
    ),
  );

  getProxiesByCompanyEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProxiesByCompanyAction>(ActionTypes.GET_PROXIES_BY_COMPANY),
      exhaustMap((action) =>
        this.machineService.GetProxiesByCompany(action.unitId).pipe(
          map((items) => new GetProxiesByCompanySuccessAction(items)),
          catchError((error) =>
            of(new GetProxiesByCompanyFailureAction(error.error)),
          ),
        ),
      ),
    ),
  );
}
