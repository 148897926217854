@if (isLoading$ | async) {
  <rh-admincenter-loading
    class="loading-spinner-container"
  ></rh-admincenter-loading>
} @else {
  <div
    class="flex h-full w-full font-brand-regular text-[18px] font-normal text-brand-gray"
    [lang]="language$ | async"
  >
    <!-- sidebar -->
    <rh-admincenter-sidebar
      (toggleSidebarEvent)="toggleSidebarEvent()"
      [sidebarOpen]="sidebarOpen"
    />
    @if (loggedUserPermissionsMisc$ | async) {
      <rh-admincenter-vertical-menu
        (toggleVerticalMenuEvent)="toggleVerticalMenuEvent()"
        [verticalMenuOpen]="verticalMenuOpen"
        [enableVerticalMenu]="enableVerticalMenu"
      />
    }

    @if (userMenuOpen) {
      <rh-admincenter-user-menu (toggleUserMenuEvent)="toggleUserMenuEvent()" />
    }

    <!-- main content-->
    <div
      [ngClass]="backgroundColor"
      class="flex h-full w-full flex-col overflow-hidden"
    >
      <!-- header -->
      <rh-admincenter-header
        [enableVerticalMenu]="enableVerticalMenu"
        (toggleSidebarEvent)="toggleSidebarEvent()"
        (toggleVerticalMenuEvent)="toggleVerticalMenuEvent()"
        (toggleUserMenuEvent)="toggleUserMenuEvent()"
      />
      <!-- main container area -->
      <main
        [ngClass]="{
          'px-small pb-small': isMobile || isTabletPortrait,
          'px-big pb-big': isWeb || isTabletLandscape,
        }"
        class="h-full w-full overflow-y-auto overflow-x-hidden"
      >
        <router-outlet
          (toggleBackgroundEvent)="toggleBackgroundEvent()"
          class="router-outlet"
        ></router-outlet>
      </main>
    </div>
  </div>
}
