<!-- Clusters Collapsed -->
<button class="flex min-h-16 w-full items-center pl-8 pr-4" (click)="toggle()">
  <div
    class="flex w-2/12 shrink-0 items-center gap-5 py-2 pr-4"
    [title]="'unitApplications.title' | translate"
  >
    <img
      src="/assets/icons/Reishauer_Icon_Apps.svg"
      width="30px"
      alt="applications"
    />
    <h2
      class="overflow-hidden text-ellipsis whitespace-nowrap text-left font-brand-regular text-xl text-[#7a93cb]"
    >
      {{ "unitApplications.title" | translate }}
    </h2>
  </div>
  @let clustersForOverview = getClustersForOverview();

  @if (clustersForOverview) {
    <div class="flex w-full gap-5">
      <div class="flex w-1/2 items-center gap-5 font-medium">
        <ng-container
          [ngTemplateOutlet]="tplApplicationOverview"
          [ngTemplateOutletContext]="{
            a: clustersForOverview[0],
          }"
        />
      </div>
      <div class="flex w-1/2 items-center gap-5 font-medium">
        <ng-container
          [ngTemplateOutlet]="tplApplicationOverview"
          [ngTemplateOutletContext]="{
            a: clustersForOverview[1],
          }"
        />
      </div>
    </div>
  }

  <div class="flex w-[50px] shrink-0 justify-end">
    <img
      src="/assets/icons/chevron-down.svg"
      width="22px"
      alt="chevron"
      [ngClass]="isOpen ? 'rotate-180' : 'rotate-0'"
    />
  </div>
</button>

<!-- Clusters Open -->
@if (isOpen) {
  <div class="flex flex-col gap-8 py-4 pl-[82px] pr-8">
    @for (cluster of filteredClusters; track cluster.id) {
      <div class="flex w-full flex-col gap-4">
        <h3 class="w-full uppercase text-[#3c5792]">{{ cluster.name }}</h3>
        <div class="flex w-full flex-col gap-4">
          @for (application of cluster.applications; track application.id) {
            @let licenceApplication = applications.get(application.id);

            <rh-admincenter-unit-application
              [companyId]="organizationId"
              [unitId]="unitId"
              [application]="application"
              [licenceApplication]="licenceApplication"
              [canUpdateLicences]="canUpdateLicences"
              [machines]="machines"
              [machineTypes]="machineTypes"
              (validToChanged)="
                editLicenceValidTo(
                  application.id,
                  $event.validTo,
                  licenceApplication
                )
              "
              (isInvoiceSentUpdated)="
                updateIsInvoiceSent(application.id, licenceApplication)
              "
              (machineAdded)="onMachineAdded($event)"
              (machineUpdated)="onMachineUpdated($event)"
              (machineDeleted)="onMachineDeleted($event)"
            />
          }
        </div>
      </div>
    }
  </div>
}

<ng-template #tplApplicationOverview let-cluster="a">
  @for (application of cluster.applications; track application) {
    <div
      [title]="application.name"
      class="flex h-12 w-1/2 items-center p-2"
      [ngClass]="getApplicationColor(application.id)"
    >
      <p
        class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-left"
      >
        {{ application.name }}
      </p>
    </div>
  }
</ng-template>
